import React from 'react';
import Home from './pages/Home';
import Footer from './components/Footer';
import './App.sass';

function App() {
    return (
        <div className="app">
            <Home />
            <Footer />
        </div>
    );
}

export default App;
