const businessInfo = {
    name: "The Riptide",
    phone: {
        url: "tel:+14155552671", // WRONG NUMBER
        display: "(415) 555-2671" // WRONG NUMBER
    },
    email: "riptidesf@gmail.com",
    email2: "booking@riptidesf.com",
    email3: "jean@riptidesf.com",
    location: {
        address: "3639 Taraval St, San Francisco, CA 94116",
        mapURL: "https://maps.app.goo.gl/TXwc6x7K4Ze6WMfdA"
    },
    businessHours: {
        monday: "3 PM - 2 AM",
        tuesday: "3 PM - 2 AM",
        wednesday: "3 PM - 2 AM",
        thursday: "3 PM - 2 AM",
        friday: "3 PM - 2 AM",
        saturday: "3 PM - 2 AM",
        sunday: "3 PM - 2 AM"
    }
};

export default businessInfo;