import React from 'react';
import businessInfo from '../businessInfo';
import { Link } from 'react-router-dom';
import './Footer.sass';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer__col">
                <Link to="/">
                    <img src="https://template-riptide.s3.us-west-1.amazonaws.com/riptide-logo-gold-simple.png" alt="Logo" />
                </Link>
            </div>
            <div className="footer__col footer__col--center">
                <h2>Contact Us</h2>
                <p>
                    <a href={businessInfo.location.mapURL} target="_blank" rel="noopener noreferrer">{businessInfo.location.address}</a>
                </p>
                <p>
                    <a href={`mailto:${businessInfo.email}`}>{businessInfo.email}</a> | <a href={`mailto:${businessInfo.email2}`}>{businessInfo.email2}</a>
                </p>
                <p>
                    Parties & Special Events: <a href={`mailto:${businessInfo.email3}`}>{businessInfo.email3}</a>
                </p>
            </div>
            <div className="footer__col">
                <div>
                    <h2>Hours</h2>
                    <div className="footer__hour">3pm-2am</div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
